import { Controller } from "stimulus"

// Time ago in words that stays up to date. Follows Rails's time scale.
//
// Example:
//
//   <time data-controller="time" datetime="2020-05-18T19:22:17Z">
//     about 1 hour ago
//   </time>

const TEXTS = {
  en: [
    [0, (_) => "less than a minute ago"],
    [1, (_) => "a minute ago"],
    [2, (m) => `${m.toFixed()} minutes ago`],
    [45, (_) => "about 1 hour ago"],
    [90, (m) => `about ${(m / 60).toFixed()} hours ago`],
    [1440, (_) => "1 day ago"],
    [2880, (m) => `${(m / 1440).toFixed()} days ago`],
    [43200, (_) => "about 1 month ago"],
    [86400, (m) => `${(m / 43200).toFixed()} months ago`],
    [525960, (_) => "about 1 year ago"],
    [1051200, (m) => `${(m / 525960).toFixed()} years ago`],
    [Number.MAX_VALUE],
  ],
  fr: [
    [0, (_) => "il y a moins d’une minute"],
    [1, (_) => "il y a une minute"],
    [2, (m) => `il y a ${m.toFixed()} minutes`],
    [45, (_) => "il y a environ 1 heure"],
    [90, (m) => `il y a environ ${(m / 60).toFixed()} heures`],
    [1440, (_) => "il y a 1 jour"],
    [2880, (m) => `il y a ${(m / 1440).toFixed()} jours`],
    [43200, (_) => "il y a environ 1 mois"],
    [86400, (m) => `il y a ${(m / 43200).toFixed()} mois`],
    [525960, (_) => "il y a environ 1 an"],
    [1051200, (m) => `il y a ${(m / 525960).toFixed()} ans`],
    [Number.MAX_VALUE],
  ],
  es: [
    [0, (_) => "hace menos de 1 minuto"],
    [1, (_) => "hace 1 minuto"],
    [2, (m) => `hace ${m.toFixed()} minutos`],
    [45, (_) => "hace alrededor de 1 hora"],
    [90, (m) => `hace alrededor de ${(m / 60).toFixed()} horas`],
    [1440, (_) => "hace 1 día"],
    [2880, (m) => `hace ${(m / 1440).toFixed()} días`],
    [43200, (_) => "hace 1 mes"],
    [86400, (m) => `hace ${(m / 43200).toFixed()} meses`],
    [525960, (_) => "hace 1 año"],
    [1051200, (m) => `hace ${(m / 525960).toFixed()} años`],
    [Number.MAX_VALUE],
  ],
};

export default class extends Controller {
  connect() {
    this.texts = TEXTS[document.documentElement.lang] || TEXTS.en;

    const seconds = 30
    setInterval(this.update, seconds * 1000)
  }

  update = () => {
    const time = Date.parse(this.element.attributes.datetime.value)
    this.element.innerText = this.timeAgoInWords(time)
  }

  // private

  // Via https://gist.github.com/boxnos/5896114
  timeAgoInWords(from, to = Date.now()) {
    const minutes = (to - from) / 60000

    const result = this.bSearch(
      minutes,
      0,
      Math.floor((this.texts.length - 1) / 2),
      this.texts.length - 1
    )[1]

    return result(minutes)
  }

  bSearch(value, lower, pos, upper) {
    if (this.texts[pos][0] <= value && value < this.texts[pos + 1][0])
      return this.texts[pos]
    else if (value < this.texts[pos][0])
      return this.bSearch(
        value,
        lower,
        Math.floor((lower + pos - 1) / 2),
        pos - 1)
    else
      return this.bSearch(
        value,
        pos + 1,
        Math.floor((pos + 1 + upper) / 2),
        upper
      )
  }
}
