import consumer from "./consumer"

const start = () => {
  const list = document.querySelector('[data-previews-list]')
  if (!list) return

  consumer.subscriptions.create("PreviewsChannel", {
    received({ update }) {
      if (update) {
        updatePreview(update)
      }
    }
  })
}

const updatePreview = ({ id, html }) => {
  const previews = document.querySelector('[data-previews-list]')
  if (!previews) return

  const preview = previews.querySelector(`[data-preview-id="${id}"]`)
  if (preview) {
    preview.outerHTML = html
  } else {
    prependHtml(previews, html)
  }
}

function prependHtml(parent, html) {
  var div = document.createElement('div')
  div.innerHTML = html.trim()
  Array.from(div.children).forEach(node => parent.prepend(node))
}

export default { start }
