import { Controller } from "stimulus"

// Rotate a sprite image by moving an element depending on the mouse position on
// the X axis over this element.

export default class extends Controller {
  static targets = ['image', 'container']

  move(e) {
    this.scrub(e.pageX)
  }

  scrub(position) {
    // This assumes a square ratio
    const height = this.imageTarget.width
    const numberOfImages = this.imageTarget.height / height

    const x = Math.max(0, position - this.imageTarget.offsetLeft)
    const index = Math.min(
      Math.floor((x / height) * numberOfImages),
      numberOfImages - 1
    )

    this.imageTarget.style.transform = `translateY(-${index * height}px)`
  }
}
